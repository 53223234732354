.customer-login-notice {
    text-align: center;
    margin: $indent__m 0;
    a {
        border: 1px solid $brand__secondary__lighter-2;
        border-radius: $block__border-radius;
        color: $brand__black;
        display:inline-block;
        text-align: center;
        font-size: $font-size__base_custom;
        padding: $indent__s $indent__base;
        width: 225px;
        .title {
            display: inline-block;
            font-weight: $font-weight__semibold;
            color: $brand__secondary__color;
        }
        .subtitle {
            font-size: $font-size__s;
            font-weight: $font-weight__heavier;
            line-height: 19px;
            display: inline-block;
        }
        &:first-child {
            background-color: $brand__gray__color;
        }
        &:hover, &:visited {
            color: $brand__black;
            text-decoration: none;
        }
    }
    @include max-screen($screen__xs) {
        a {
            &:first-child {
                margin-bottom: $indent__m;
            }
        }
    }

}
@include min-screen($screen__s) {
    .customer-login-notice {
        display: flex;
        text-align: inherit;
        margin: $indent__l 0;
        a:first-child {
            margin-right: $grid-gutter-width;
        }
    }
}
