#open-size-chart {
    position: absolute;
    right: 0;
    top: 4px;
    background-color: transparent;
    color: $brand__primary__color;
    padding: 0;
    text-transform: none;
    font-weight: 400;
    font-size: 16px;

    &:hover {
        text-decoration: underline;
    }
}

.modal-slide.size-chart-sliderover {
    left:0;
    right:0;
    max-width: 334px;

    @include min-screen($screen__m) {
        max-width: 450px;
    }

    .modal-inner-wrap {
        transform: translateX(-100%);
        border-top-left-radius: 6px;

        .modal-header {
            position: sticky;
            top: 0;
            border-top-left-radius: 6px;
            background-color: #222950;
            border-bottom: 4px solid $brand__primary__color;
            padding: 29px 0;

            .modal-title {
                text-transform: none;
                color: #FFF;
                font-weight: 200;
                font-size: 20px;
                text-align: center;
                margin-bottom: 0;
            }

            .action-close {
                padding: 0;
                right: 23px;
                top: 26px;

                &:hover {
                    &::before {
                        color: #FFF;
                    }
                }

                &::before {
                    content: '\f00d';
                    font-size: 28px;
                    font-weight: 200;
                    margin-right: 0;
                }
            }
        }

        .modal-content {
            margin-top: 20px;
            padding: 0 25px 25px;

            .modal-body-content {
                color: #222950;
                font-size: 16px;
                font-family: $font-family__brand;

                img {
                    margin: 20px 0;
                    object-fit: contain;
                    width: 211px;

                    @include min-screen($screen__m) {
                        width: 305px;
                    }
                }
            }
        }
    }
}
